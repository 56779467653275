export function formatProtocol(protocol: string) {
  switch (protocol) {
    case 'uniswapv3':
      return 'Uniswap V3'

    case 'holdstationswap':
      return 'Holdstation Swap'

    case 'syncswap':
      return 'SyncSwap'

    case 'koifinance':
      return 'Koi Finance'

    case 'iziswap':
      return 'iZUMi Swap'

    case 'velocore':
      return 'Velocore'

    case 'spacefi':
      return 'SpaceFi'

    case 'vesync':
      return 'veSync'

    case 'zkswap':
      return 'zkSwap'

    case 'pancakev3':
      return 'PancakeSwap V3'

    case 'pancakev2':
      return 'PancakeSwap V2'

    case 'ezkalibur':
      return 'eZKalibur'

    case 'syncswapv2':
      return 'SyncSwap V2'

    case 'syncswapv21':
      return 'SyncSwap V21'

    // BERACHAIN
    case 'beraswap':
      return 'BeraSwap'

    case 'kodiakv2':
      return 'Kodiak V2'

    case 'kodiakv3':
      return 'Kodiak V3'

    case 'burrbear':
      return 'BurrBear'

    default:
      protocol
  }

  return protocol
}

// iziswap  --> iZUMi Swap
// ezkalibur  --> eZKalibur
// zkswap  --> zkSwap
// vesync  --> veSync

export function getLower(protocol: string) {
  switch (protocol) {
    case 'iziswap':
      return true

    case 'ezkalibur':
      return true

    case 'zkswap':
      return true

    case 'vesync':
      return true

    default:
      return false
  }
}
