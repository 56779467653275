import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useToggleAccountDrawer } from 'components/AccountDrawer/MiniPortfolio/hooks'
import { ButtonV3 } from 'components/Button/ButtonV3'
import { toastSuccess } from 'components/toast'
import { toastError } from 'components/toast/toast-success'
import { useCallback, useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { BrigeForm } from './bridge'
import { BERA_OFT, ZKSYNC_OFT } from './constant'
import { useBalance, useEstimateGas } from './data'
import { approve, bridgeHold } from './utils'

export function Button({ fee }: { fee: number }) {
  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    control,
  } = useFormContext<BrigeForm>()
  const { account } = useWeb3React()
  const toggleWalletDrawer = useToggleAccountDrawer()
  const { provider, chainId } = useWeb3React()
  const [loadingNetwork, setLoadingNetwork] = useState(false)

  // const toChainId = watch('to.chainId')

  const amount = useWatch({ control, name: 'value' })
  const fromChainId = useWatch({ control, name: 'from.chainId' })

  const { data, refetch } = useBalance()

  const resetState = useCallback(() => {
    setValue('value', '')
  }, [setValue])

  const isNeedApprove = useMemo(() => {
    if (!amount || new BigNumber(amount).eq(0)) {
      return false
    }

    if (!data?.[1]) {
      return false
    }

    if (new BigNumber(amount).multipliedBy(1e18).gt(data[1].toString())) {
      return true
    }

    return false
  }, [amount, data])

  const { isError } = useEstimateGas({ amount, fee, isNeedApprove })

  const isNeedSwitchNetwork = useMemo(() => {
    if (fromChainId !== chainId) {
      return true
    }

    return false
  }, [chainId, fromChainId])

  const disable = useMemo(() => {
    if (new BigNumber(data?.[0]?.toString() ?? '0').eq(0)) {
      return true
    }

    if (new BigNumber(amount).multipliedBy(1e18).gt(new BigNumber(data?.[0]?.toString() ?? '0'))) {
      return true
    }

    if (new BigNumber(amount).lt(1)) {
      return true
    }

    if (new BigNumber(amount).eq(0)) {
      return true
    }

    if (isError) {
      return true
    }

    return false
  }, [amount, data, isError])

  const textConfirm = useMemo(() => {
    if (new BigNumber(data?.[0]?.toString() ?? '0').eq(0)) {
      return 'Confirm'
    }

    if (new BigNumber(amount).multipliedBy(1e18).gt(new BigNumber(data?.[0]?.toString() ?? '0'))) {
      return 'Insufficient Amount'
    }

    if (new BigNumber(amount).lt(1)) {
      return 'Minimum 1 HOLD'
    }

    if (isError) {
      return 'Insufficient Gas'
    }

    return 'Confirm'
  }, [amount, data, isError])

  const approveHoldBridge = useCallback(async () => {
    if (!provider || !chainId) {
      return
    }

    try {
      let oft = ZKSYNC_OFT.oft
      let hold = ZKSYNC_OFT.hold
      // let eid = BERA_OFT.eid
      // zk
      if (chainId === BERA_OFT.chainId) {
        oft = BERA_OFT.oft
        hold = BERA_OFT.hold
        // eid = ZKSYNC_OFT.eid
      }

      const tx = await approve({
        spender: oft,
        tokenAddress: hold,
        signer: provider.getSigner(),
      })
      const receipt = await tx.wait()
      if (receipt.status) {
        console.log('success approve', receipt.transactionHash)
        toastSuccess({
          title: 'Approve Successful',
          txh: `https://era.zksync.network/tx/${receipt.transactionHash}`,
        })

        refetch()
        return
      }

      console.log('fail approve')
    } catch (error) {
      console.log('Error')

      toastError('Approve Failed')
    }
  }, [chainId, provider, refetch])

  const bridgeCall = useCallback(async () => {
    if (!provider || !chainId) {
      return
    }

    try {
      let oft = ZKSYNC_OFT.oft
      let eid = BERA_OFT.eid
      let name = BERA_OFT.name
      // zk_sepolia
      if (chainId === BERA_OFT.chainId) {
        oft = BERA_OFT.oft
        eid = ZKSYNC_OFT.eid
        name = ZKSYNC_OFT.name
      }
      const signer = provider.getSigner()
      const address = await signer.getAddress()
      const tx = await bridgeHold({
        oftAddress: oft,
        toAddress: address,
        dstEid: eid,
        signer,
        amountHold: amount,
        fee, // fee 0.2/100 -> sau call contract
      })
      const receipt = await tx.wait()
      if (receipt.status) {
        console.log('success bridge', receipt.transactionHash)

        toastSuccess({
          title: `Bridge successful. Your token will arrive to ${name} shortly.`,
          txh: `https://layerzeroscan.com/tx/${receipt.transactionHash}`,
        })
        resetState()

        refetch()
        return
      }
    } catch (error) {
      console.log('fail bridge', error)

      toastError('Bridge Failed')
    }
  }, [amount, chainId, fee, provider, refetch, resetState])

  const switchNetwork = async (chainId: number) => {
    if (!provider?.provider?.request) {
      console.error('Provider does not support network switching')
      return
    }

    try {
      setLoadingNetwork(true)
      await provider.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
    } catch (error: any) {
      if (error.code === 4902) {
        try {
          let chainName = ZKSYNC_OFT.name
          let chainId = ZKSYNC_OFT.chainId
          let name = ZKSYNC_OFT.name
          let symbol = ZKSYNC_OFT.symbol
          let rpcUrls = ZKSYNC_OFT.rpcUrls
          let blockExplorerUrls = ZKSYNC_OFT.blockExplorerUrls
          if (fromChainId === BERA_OFT.chainId) {
            chainName = BERA_OFT.name
            chainId = BERA_OFT.chainId
            name = BERA_OFT.name
            symbol = BERA_OFT.symbol
            rpcUrls = BERA_OFT.rpcUrls
            blockExplorerUrls = BERA_OFT.blockExplorerUrls
          }
          await provider.provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName,
                nativeCurrency: {
                  name,
                  symbol,
                  decimals: 18,
                },
                rpcUrls,
                blockExplorerUrls,
              },
            ],
          })
        } catch (addError) {
          console.error('Failed to add network', addError)
        }
      } else {
        console.error('Failed to switch network', error)
      }
    } finally {
      setLoadingNetwork(false)
      setValue('value', '')
    }
  }

  if (!account) {
    return (
      <ButtonV3
        onClick={toggleWalletDrawer}
        className="px-5 h-12 bg-white rounded-2xl flex justify-center items-center gap-2.5"
      >
        <span className="text-black text-sm font-medium">Connect Wallet</span>
      </ButtonV3>
    )
  }

  if (isNeedSwitchNetwork) {
    return (
      <ButtonV3
        isLoading={loadingNetwork}
        disabled={loadingNetwork}
        onClick={() => switchNetwork(fromChainId)}
        className="px-5 h-12 bg-white rounded-2xl flex justify-center items-center gap-2.5"
      >
        <span className="text-black text-sm font-medium">Switch network</span>
      </ButtonV3>
    )
  }

  if (isNeedApprove) {
    return (
      <ButtonV3
        isLoading={isSubmitting}
        disabled={isSubmitting || disable}
        onClick={handleSubmit(approveHoldBridge)}
        className="px-5 h-12 bg-white rounded-2xl flex justify-center items-center gap-2.5"
      >
        <span className="text-black text-sm font-medium">Approve</span>
      </ButtonV3>
    )
  }

  return (
    <ButtonV3
      isLoading={isSubmitting}
      disabled={isSubmitting || disable}
      onClick={handleSubmit(bridgeCall)}
      className="px-5 h-12 bg-white rounded-2xl flex justify-center items-center gap-2.5"
    >
      <span className="text-black text-sm font-medium">{textConfirm}</span>
    </ButtonV3>
  )
}
