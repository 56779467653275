import brokenTokenList from './tokenLists/broken.tokenlist.json'
import defaultTokenList from './tokenLists/default.tokenlist.json'

export const UNI_LIST = ''
export const UNI_EXTENDED_LIST = ''
// TODO(WEB-2282): Re-enable CMC list once we have a better solution for handling large lists.
// const CMC_ALL_LIST = 'https://s3.coinmarketcap.com/generated/dex/tokens/eth-tokens-all.json'
const COINGECKO_ZKSYNC_LIST = 'https://tokens.coingecko.com/zksync/all.json'
const HOLDSTATION_LIST = 'https://api.holdstation.com/api/aia-tokens'
const HOLDSTATION_LIST_BERA = 'https://api.holdstation.com/api/coins/holdso/chain/80094'

export const ZKSYNC_LIST = ''

export const UNSUPPORTED_LIST_URLS: string[] = []

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = []
export const DEFAULT_INACTIVE_LIST_URLS: string[] = [
  //  CMC_ALL_LIST,
  COINGECKO_ZKSYNC_LIST,
  HOLDSTATION_LIST,
  HOLDSTATION_LIST_BERA,
  ...UNSUPPORTED_LIST_URLS,
]

export const DEFAULT_LIST_OF_LISTS: string[] = [...DEFAULT_ACTIVE_LIST_URLS, ...DEFAULT_INACTIVE_LIST_URLS]

export const BROKEN_TOKEN_LIST = brokenTokenList
export const DEFAULT_TOKEN_LIST = defaultTokenList

export const DEFAULT_TOKEN_OUT = '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C'
